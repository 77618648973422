import React, { useRef } from "react";
import styled from "styled-components";

import headerLogo from "resource/image/promotion/kot_logo@2x.png";
import mobileLogo from "resource/image/promotion/mobile_logo@2x.png";
import introBackground from "resource/image/promotion/banner@2x.png";
import mobileBackground from "resource/image/promotion/web_banner.png";

//import holdingMobile from "resource/image/promotion/holding_mobile.png";
import appStore from "resource/image/promotion/os_banner_appicon@2x.png";
import playStore from "resource/image/promotion/play_banner_appicon@2x.png";

import appStoreFooter from "resource/image/promotion/os_footer_appicon@2x.png";
import playStoreFooter from "resource/image/promotion/play_footer_appicon@2x.png";

import feature1 from "resource/image/promotion/f1_image@2x.png";
import feature2 from "resource/image/promotion/f2_image@2x.png";
import feature3 from "resource/image/promotion/f3_image@2x.png";

import aboutUsBackground from "resource/image/promotion/aboutus_bg@2x.png";

import footerLogo from "resource/image/promotion/footer_logo@2x.png";

import { Menu, Close } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';

export const PromotionPage = () => {
	const featureRef = useRef(null);
	const aboutRef = useRef(null);
	const contactRef = useRef(null);

	const scrollToFeature = () => {
		featureRef.current.scrollIntoView({ behavior: "smooth" });
	};
	/*
	const scrollToAboutUs = () => {
		aboutRef.current.scrollIntoView({ behavior: "smooth" });
	};
	*/
	const scrollToContactUs = () => {
		contactRef.current.scrollIntoView({ behavior: "smooth" });
	};

	const [open, setOpen] = React.useState(false);

	return (
		<RootContainer>
			<NavbarContainer>
				<NavbarLeftContainer>
					<Logo src={headerLogo}></Logo>
					<MobileLogo src={mobileLogo}></MobileLogo>
				</NavbarLeftContainer>
				<NavbarRightContainer>
					<IconButtonContainer>
						<IconButton aria-label="選單" onClick={() => setOpen(!open)} style={{padding: `20px`, color: `#333399` }}>
							{!open && ( <Menu style={{ height: "20px" }} />)}
							{open && (<Close style={{ height: "20px" }} />)}
						</IconButton>
					</IconButtonContainer>
					<NavbarItemsContainer open={open}>
						<NavbarItem onClick={scrollToFeature}>程式特點</NavbarItem>
						{/* <NavbarItem onClick={scrollToAboutUs}>關於我們</NavbarItem> */}
						<NavbarItem onClick={scrollToContactUs}>聯繫我們</NavbarItem>
					</NavbarItemsContainer>
					<MobileNavbarItemsContainer open={open}>
						<NavbarItem onClick={scrollToFeature}>程式特點</NavbarItem>
						{/* <NavbarItem onClick={scrollToAboutUs}>關於我們</NavbarItem> */}
						<NavbarItem onClick={scrollToContactUs}>聯繫我們</NavbarItem>
					</MobileNavbarItemsContainer>
				</NavbarRightContainer>
			</NavbarContainer>
			<MobileIntroBackground src={mobileBackground} ></MobileIntroBackground>
			<IntroContainer >
				<IntroBackground src={introBackground} ></IntroBackground>
				<IntroContentContainer>
					<IntroTitleText>
						<div>一個具實時資訊，互相交流的</div> <div>升學及社交網絡平台。</div>
					</IntroTitleText>
					<IntroDownloadContainer>
						<a href="https://itunes.apple.com/app/id1525688803" target="_blank" rel="noopener noreferrer">
							<AppStoreImg src={appStore} width={"120px"} height={"44px"}></AppStoreImg>
						</a>
						<a href="https://play.google.com/store/apps/details?id=innovthoughts.kidsontrack" target="_blank" rel="noopener noreferrer">
							<PlayStoreImg src={playStore} width={"149px"} height={"44px"} style={{ marginLeft:`15.5px` }}></PlayStoreImg>
						</a>
					</IntroDownloadContainer>
				</IntroContentContainer>
				
			</IntroContainer>

			<FeatureContainer ref={featureRef}>
				<FeatureItem>
					<FeatureItemImage src={feature1} />
					<FeatureItemTitle>
						<div>想方便得到子女升學資訊或安排課程時間?</div>
					</FeatureItemTitle>
					<FeatureItemText>
						<div>您現在只需要註冊並登錄帳戶，並為子女建立其個人帳號。使用我們的搜索引擎選擇専屬的心儀學校或補習社，您就能獲得相關的資訊或事件。</div>
					</FeatureItemText>
				</FeatureItem>
				<FeatureItem>
					<FeatureItemImage src={feature2} />
					<FeatureItemTitle>
						<div>怕錯過學校和教育中心的重要資訊和事件?</div>
					</FeatureItemTitle>
					<FeatureItemText>
						<div>我們會為您所訂閱的學校，補習社或自訂事件提供通知和提醒功能，您將不會錯過或忘記任何資訊和事件。</div>
					</FeatureItemText>
				</FeatureItem>
				<FeatureItem>
					<FeatureItemImage src={feature3} style={{ marginTop:`1.78%`, marginBottom:`1.78%`}} />
					<FeatureItemTitle>
						<div>想與朋友分享您的信息，活動或興趣?</div>
					</FeatureItemTitle>
					<FeatureItemText>
						<div>使用我們伴你童行的社交網絡平台，與您的朋友，其他家長保持聯繫，互相分享教育信息。隨時隨地即時分享，你絕不會錯過任何東西。</div>
					</FeatureItemText>
				</FeatureItem>
			</FeatureContainer>
			{!true && ( 
			<AboutUsContainer ref={aboutRef} style={{ backgroundImage: `url(${aboutUsBackground})` }}>
				<AboutUsContentContainer>
					{/* <AboutUsLodash>_</AboutUsLodash>
					<AboutUsTitle>關於我們</AboutUsTitle> */}
					{/* <AboutUsText>
					</AboutUsText>
					<AboutUsText>
					</AboutUsText> */}
				</AboutUsContentContainer>
				<img src={aboutUsBackground} style={{ visibility: "hidden", width: "100%" }} alt=""></img>
			</AboutUsContainer>
			)}
			<HorizontalLine></HorizontalLine>
			<ContactUsContainer ref={contactRef}>
				<FooterLogo src={footerLogo}></FooterLogo>
				<FooterLeftContainer>
					<ContactUs>
						<ContactUsTitle>聯繫我們</ContactUsTitle>
						<ContactUsText>
							<div>客戶服務電郵:</div>
							<ContactUsEmail href="mailto:cs@squarebox.com.hk">cs@squarebox.com.hk</ContactUsEmail>
						</ContactUsText>
					</ContactUs>
					<OtherTabsContainer>
						{/* <OtherTab>關於我們</OtherTab> */}
						<OtherTab>常見問題</OtherTab>
						<OtherTab href="https://kidsontrack.hk/doc/Privacy_Policy.html" target="_blank">
							隱私政策
						</OtherTab>
						<OtherTab href="https://kidsontrack.hk/doc/Terms_and_Conditions.html" target="_blank">
							條款及細則
						</OtherTab>
					</OtherTabsContainer>
				</FooterLeftContainer>
				<FooterRightContainer>
						<AppDownloadTitle>應用程式下載</AppDownloadTitle>
						<AppDownloadContainer>
							<AppDownload>
								iOS App Store
								<a href="https://itunes.apple.com/app/id1525688803" target="_blank" rel="noopener noreferrer">
									<AppStoreFooterImg src={appStoreFooter} width={"109px"} height={"40px"}></AppStoreFooterImg>
								</a>
							</AppDownload>
							<AppDownload>
								Android Google Play
								<a href="https://play.google.com/store/apps/details?id=innovthoughts.kidsontrack" target="_blank" rel="noopener noreferrer">
									<PlayStoreFooterImg src={playStoreFooter} width={"135px"} height={"40px"}></PlayStoreFooterImg>
								</a>
							</AppDownload>
						</AppDownloadContainer>
				</FooterRightContainer>
			</ContactUsContainer>
		</RootContainer>
	);
};

const Logo = styled.img`
	width:190px;
	height:190px;
	position:relative; z-index:2;
	@media only screen and (min-width:768px)  and (max-width:1365px) {
		width:13.9vw; height:13.9vw;
	}
	@media only screen and (max-width:767px) {
		display:none;
	}
`;
const MobileLogo = styled.img`
	width:40px;
	height:39px;
	position:relative; z-index:2;
	display:none;
	@media only screen and (max-width:767px) {
		display:initial;
	}
`;
const RootContainer = styled.div`
	width: 100%;
	max-width: 1366px;
	margin: auto;
	@media only screen and (max-width:767px) {
		max-width: auto;
		min-width: auto;
		overflow-x:hidden;
	}
`;

const NavbarContainer = styled.div`
	display: flex;
	padding: 11px 10.2% 10px 8.7%;
	position:relative;
	@media only screen and (max-width:767px) {
		padding: 10px 24px 10px 24px;
	}
`;
const NavbarLeftContainer = styled.div`
	flex: 1; position:absolute; top:-27px; 
	@media only screen and (max-width:767px) {
		position:initial;
	}
	@media only screen and (min-width:768px)  and (max-width:1365px) {
		top: -1.98vw;
	}
`;
const NavbarRightContainer = styled.div`
	flex: 1;
	@media only screen and (max-width:767px) {
		width:100%;
	}
`;

const NavbarItemsContainer = styled.div`
	display: flex;
	justify-content: flex-end;

	@media only screen and (max-width:767px) {
		display:none;
	}

`;

const MobileNavbarItemsContainer = styled.div`
	display: none;
	justify-content: flex-end;
	flex-direction:column;
	background-color:#ffffff;
	padding: 10px 0 30px 0;
	transition: transform 0.3s ease-in-out;
	position:absolute;
	top:60px; left:0; width:100%;
	
	@media only screen and (max-width:767px) {
		display:flex;
		transform: ${(props) => props.open===true ? 'translateX(0)' : 'translateX(100%)'};
	}
`;

const NavbarItem = styled.div`
	cursor: pointer;
	margin: 0 0 0 25px;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 2.46;
	letter-spacing: normal;
	text-align: left;
	color: #000000;

	@media only screen and (max-width:767px) {
		padding: 10px 24px;
		font-size: 19px;
		line-height: 1.68;
		margin:0;
	}
	@media only screen and (min-width:768px)  and (max-width:1365px) {
		font-size: 0.95vw;
	}
`;

const IconButtonContainer = styled.div`
	display:none;
	position:absolute;
	right:0px; 
	top:0px;
	@media only screen and (max-width:767px) {
		display:block;
	}
`;


const IntroContainer = styled.div`
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: contain;
	position: relative;
	display: flex;
	justify-content: left;
	background-image: url(${introBackground}) ;
	@media only screen and (max-width:767px) {
		background-image:none;
		justify-content: center;
	}
`;

const IntroBackground = styled.img`
	visibility: hidden;
	width: 100%;
	@media only screen and (max-width:767px) {
		display:none;
	}
`;

const MobileIntroBackground = styled.img`
	width: 100%;
	display:none;
	@media only screen and (max-width:767px) {
		display: initial;
	}
`;

const IntroContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	top: 22.5%; left:11.2%;
	max-width:40.9%;
	@media only screen and (max-width:767px) {
		position: initial;
		max-width:initial;
	}
	@media only screen and (min-width:768px)  and (max-width:1365px) {
		font-size: 3.074vw;
	}
	
`;

const IntroTitleText = styled.div`
	font-size: 42px;
	line-height: 1.38;
	text-align: center;
	font-weight: 900;
	color: #ffffff;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);


	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	
	@media only screen and (min-width:768px)  and (max-width:1365px) {
		font-size: 3.074vw;
	}

	@media only screen and (max-width:767px) {
		font-size: 26px;
		color: #000000;
		text-shadow:none;
	}
	


`;
/*
const IntroText = styled.div`
	font-size: 20px;
	line-height: 30px;
	text-align: center;
	margin-top: 10px;
`;
*/
const IntroDownloadContainer = styled.div`
	text-align: center;
	margin-top: 15px;
`;
/*
const HoldingMobileImg = styled.img`
	position: absolute;
	width: 48%;
	left: 34%;
	bottom: 0px;
	z-index: 1;
`;
*/
const FeatureContainer = styled.div`
	display: flex;
	flex-direction: row;
	padding: 6px 30px 6px 30px;

	@media only screen and (max-width:767px) {
		flex-direction: column;
	}
`;

const FeatureItem = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 1.5rem;
	@media only screen and (max-width:767px) {
		padding: 19px 7px;
	}
`;

const FeatureItemImage = styled.img`
	/*width: 370px;
	height: 274px;*/
	width:100%;
`;

const FeatureItemTitle = styled.div`
	margin-top: 10px;
	font-size: 26px;
	line-height: 36px;
	text-align: center;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	color: #000000;
	padding-left:20px;
	padding-right:20px;
	
	@media only screen and (max-width:767px) {
		font-size: 23px;
		line-height: 1.3;
		letter-spacing: -0.46px;
		margin-top: 23px;
		padding-left:10px;
		padding-right:10px;
	}
	@media only screen and (min-width:768px)  and (max-width:1365px) {
		font-size: 1.90vw;
		line-height:2.64vw;
		padding-left:1.46vw;
		padding-right:1.46vw;
	}

`;

const FeatureItemText = styled.div`
	margin-top: 10px;
	font-size: 14px;
	line-height: 22px;
	text-align: center;
	color: #000000;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	padding-left:20px;
	padding-right:20px;
		
	@media only screen and (max-width:767px) {
		line-height: 1.43;
		padding-left:10px;
		padding-right:10px;
	}
	@media only screen and (min-width:768px)  and (max-width:1365px) {
		font-size: 1.02vw;
		line-height:1.61vw;
		padding-left:1.46vw;
		padding-right:1.46vw;
	}

`;

const AboutUsContainer = styled.div`
	background-repeat: no-repeat;
	background-size: contain;
	padding: 2rem 2rem 0 2rem;
	position: relative;
`;

const AboutUsContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	top: 100px;
	left: 80px;
`;
/*
const AboutUsLodash = styled.div`
	color: white;
	position: absolute;
	font-size: 36px;
	line-height: 36px;
	top: 6px;
	left: -25px;
`;

const AboutUsTitle = styled.div`
	margin-top: 10px;
	font-size: 26px;
	line-height: 36px;
	font-weight: bold;
	color: white;
	text-align: left;
	font-weight: bold;
`;

const AboutUsText = styled.div`
	width: 600px;
	margin-top: 20px;
	font-size: 14px;
	line-height: 22px;
	color: white;
	text-align: left;
`;
*/
const HorizontalLine = styled.hr`
	height:0px; border: 1px #dddddd solid; 
	margin:17px 0 41px 0;
	@media only screen and (max-width:767px) {
		margin:25px 0;
	}
`;

const ContactUsContainer = styled.div`
	display: flex;
	flex-direction: row;
	padding: 0 159px 2rem 159px;
	@media only screen and (max-width:767px) {
		padding: 0 0 2rem 0;
		flex-direction:column;
	}
	@media only screen and (min-width:768px)  and (max-width:1365px) {
		padding: 0 11.6vw 2rem 11.6vw;
	}
	
`;
const ContactUs = styled.div`
	display: flex;
	flex-direction: column;
	padding-right: 2rem;
	border-right: 2px solid #dddddd;
	justify-content: center;
	@media only screen and (max-width:767px) {
		border-right: none;
		padding-right: 0;
		height:auto;
	}
`;

const ContactUsTitle = styled.div`

	font-size: 20px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
	@media only screen and (max-width:767px) {
		text-align:center;
	}
	@media only screen and (min-width:768px)  and (max-width:1365px) {
		font-size: 1.46vw;
	}


`;

const ContactUsText = styled.div`

	margin-top: 12px;

	font-size: 14px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.41;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
	@media only screen and (max-width:767px) {
		text-align:center;
		margin-top: 6px;
		line-height: 1.31;
	}
	@media only screen and (min-width:768px)  and (max-width:1365px) {
		font-size: 1.02vw;
	}

`;

const ContactUsEmail = styled.a`
	color: #333399;
	font-size: 14px;
  	font-weight: normal;
  	font-stretch: normal;
  	font-style: normal;
  	line-height: 1.41;
  	letter-spacing: normal;
	text-align: left;
	margin: 1px 0 0;

	@media only screen and (max-width:767px) {
	
	}

	@media only screen and (min-width:768px)  and (max-width:1365px) {
		font-size: 1.02vw;
	}
`;

const OtherTabsContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: 2rem;
	@media only screen and (max-width:767px) {
		flex-direction: row;
		padding-left: 0;
		margin-top:26px;
	}
	@media only screen and (min-width:768px)  and (max-width:1365px) {
		padding-left: 2.34vw;
	}
`;

const OtherTab = styled.a`
	cursor: pointer;
	color: black;
	text-decoration: none;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 2.41;
	letter-spacing: normal;
	text-align: left;
	@media only screen and (max-width:767px) {
		padding-left: 5px;
		padding-right: 5px;
		:not(:last-child):after {
			content:"|";
			padding-left:10px;
		}
	}
	@media only screen and (min-width:768px)  and (max-width:1365px) {
		font-size: 1.02vw;
	}
	

`;

const FooterLeftContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	margin-left: 4rem;
	border-right: 2px solid #dddddd;
	align-items: center;
	@media only screen and (max-width:767px) {
		order:2;
		border-right: none;
		flex-direction: column;
		margin-left: 0;
		margin-top: 18px;
	}
	@media only screen and (min-width:768px)  and (max-width:1365px) {
		margin-left: 4.7vw;
	}
`;

const FooterRightContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	margin-left: 26px;
	align-items: start;
	@media only screen and (max-width:767px) {
		order:1;
		align-items: center;
		margin-left: 0;
	}
	@media only screen and (min-width:768px)  and (max-width:1365px) {
		margin-left: 1.90vw;
	}
`;

const AppDownloadTitle = styled.div`

  	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 2.0;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
	width:100%;
	@media only screen and (max-width:767px) {
		width:auto;
		font-size: 19px;
		line-height: 1.68;
	}
	@media only screen and (min-width:768px)  and (max-width:1365px) {
		font-size: 1.02vw;
	}

`;

const FooterLogo = styled.img`
	width:100px;
	@media only screen and (max-width:767px) {
		display:none;
	}
	@media only screen and (min-width:768px)  and (max-width:1365px) {
		width: 7.3vw;
		height:7.3vw;
	}
`;

const AppDownloadContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

const AppDownload = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 12px;
	margin-right: 10px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 2.17;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
	font-family: SF Pro Display;
	@media only screen and (max-width:767px) {
		margin-right: 8px; margin-left:8px;
	}
	@media only screen and (min-width:768px)  and (max-width:1365px) {
		font-size:0.88vw;
	}

`;


const AppStoreImg = styled.img`
	width: 120px;
	height: 44px;
	@media only screen and (min-width:768px)  and (max-width:1365px) {
		width: 8.78vw;
		height: 3.22vw;
	}

`;

const PlayStoreImg = styled.img`
	width: 149px;
	height: 44px;
	@media only screen and (min-width:768px)  and (max-width:1365px) {
		width: 10.90vw;
		height: 3.22vw;
	}
`;

const AppStoreFooterImg = styled.img`
	width: 109px;
	height: 40px;
	@media only screen and (min-width:768px)  and (max-width:1365px) {
		width: 7.98vw;
		height: 2.92vw;
	}
`;

const PlayStoreFooterImg = styled.img`
	width: 135px;
	height: 40px;
	@media only screen and (min-width:768px)  and (max-width:1365px) {
		width: 9.88vw;
		height: 2.92vw;
	}
`;